<script>
import vSelect from "vue-select";

export default {
  name: "TeamUserCreation",
  components: {vSelect},
  props: {
    editing: {type: Boolean, default: false}
  },
  data: () => {
    return {
      userTeams: [],
      directorUserSelect: [],
      loading: false,
      getUserTeamsUrl: 'users',
      urlSaveUserTeam: 'teams/users',
      initialData: null,
      server_data_send: {
        user_id: [],
        is_team_director: null
      }
    }
  },
  watch: {
    'server_data_send.user_id'(newValue, oldValue) {
      console.log("this is the new value of the team id ", this.userTeams)
      this.directorUserSelect = this.userTeams.filter((x) => newValue.includes(x.value.toString()))
      console.log("this is the user to select for the director", dataUserSelectedDirector)
    }
  },
  methods: {
    async fetchUserData() {
      await this.$http.get(this.getUserTeamsUrl + `?keyword=&page=1&size=100000&order_filed=user_firstname&order=asc&team_id=${this.$route.params.id}`).then((response) => {
        this.userTeams = response.data.data.data.map((user) => ({
          value: user.user_id,
          label: user.user_lastname + ' ' + user.user_firstname + ' (' + user.user_email + ' )'
        }))
      }).catch((error) => console.log({error}))
    },
    openModal(create, data, title) {
      this.initialData = data
      this.forceTitle = title
      this.create = create
      this.$refs.teamUserModal.show()
    },
    async handleSave() {
      this.loading = true
      await this.$http.put(this.urlSaveUserTeam, {
        team_id: this.$route.params.id,
        user_id: this.server_data_send.user_id || [this.initialData.user_id],
        user_director_id: this.server_data_send.is_team_director || this.initialData.user_id
      }).then((response) => {
        console.log("this is the response", response)
      }).catch((error) => this.$errorToast('An error has occurred, please try again later')).finally(() => {
        this.loading = false
        this.$refs.teamUserModal.hide()
        this.$emit('reload-table')
      })
    }
  },
  mounted() {
    this.fetchUserData()
  }
}
</script>

<template>
  <b-modal ref="teamUserModal" centered>
    <validation-observer v-if="!editing" ref="form" tag="div" class="my-2">
      <b-form>
        <b-row>
          <b-col cols="12">
            <label>List of User : </label>
            <v-select v-model="server_data_send.user_id"
                      placeholder="Select the user to add to the team"
                      :clearable="true"
                      :multiple="true"
                      :options=" userTeams" transition="" label="label"
                      class="w-100" :reduce="i => i.value.toString()"/>
          </b-col>
          <b-col cols="12 mt-2">
            <label>Choose director : </label>
            <v-select v-model="server_data_send.is_team_director"
                      placeholder="Select the director of the team "
                      :clearable="true"
                      :disabled="server_data_send.user_id.length === 0"
                      :options="directorUserSelect" transition="" label="label"
                      class="w-100" :reduce="i => i.value.toString()"/>
          </b-col>
        </b-row>

      </b-form>
    </validation-observer>
    <div>
      Do you want to set the <strong> {{initialData.user_firstname}} {{initialData.user_lastname}} <i>({{initialData.user_email}})</i></strong> as the administrator of this team?
    </div>
    <template #modal-footer>
      <b-button variant="warning" :disabled="loading" @click="$refs.teamUserModal.hide()" class="mx-2">
        {{ $t('button~cancel') }}
      </b-button>
      <b-button variant="primary" :disabled="editing ? false : loading || server_data_send.is_team_director === null" @click="handleSave">
        <b-spinner v-if="loading" small/>
         {{ editing ? 'Confirm' : $t('button~save') }}
      </b-button>
    </template>

  </b-modal>
</template>

<style scoped>

</style>
